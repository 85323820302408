
import AppHeader from "@/components/AppHeader.vue";
import Error from "@/components/Error.vue";
import Page from "@/components/Page.vue";
import useError from "@/composables/useError";
import {
  PageParamsInputType,
  useGetUsersPaginationQuery,
} from "@/graphql/types";
import { useResult } from "@vue/apollo-composable";
import gql from "graphql-tag";
import { FilterMatchMode } from "primevue/api";
import { defineComponent, ref } from "vue";
import { ApprovalStateEnum } from "@/models/Enum";
import { formatDate } from "@/utils/date";
import Pagination from "@/components/pagination/Pagination.vue";
import { PageState } from "primevue/paginator";
import usePluralFilter from "@/composables/usePluralFilter";

// Query
gql`
  query GetUsersPagination($pageParams: PageParamsInputType!) {
    usersPagination(pageParams: $pageParams) {
      items {
        userId
        firstname
        lastname
        email
        mitID
        administrator
        approvalState
        enabled
        lastLogin
        organization {
          organizationId
        }
      }
      total
    }
  }
`;

export default defineComponent({
  inheritAttrs: false,
  components: {
    AppHeader,
    Page,
    Error,
    Pagination,
  },
  name: "users",
  setup() {
    const pageParams = ref<PageParamsInputType>({
      pageSize: 10,
      currentPage: 1,
    });

    const { result, loading, error, refetch } = useGetUsersPaginationQuery({
      pageParams: pageParams.value,
    });

    function onPageChange(event: PageState) {
      pageParams.value.pageSize = event.rows;

      pageParams.value.currentPage = event.page + 1;

      refetch({ pageParams: pageParams.value });
    }

    return {
      users: useResult(result),
      error: useError(error),
      loading,
      filters: useFilters(),
      ApprovalStateEnum,
      formatDate,
      onPageChange,
      usePluralFilter,
      pageParams,
    };
  },
});

function useFilters() {
  return ref({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
}
